@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700');

// Our variables
$base-font-family: 'IBM Plex Sans','Helvetica Neue', Helvetica, 'Segoe UI', Arial, freesans, sans-serif;
$base-font-size:   14px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.7;
$base-letter-space: 0.00rem;

$spacing-unit:     30px;

$text-color:       #474658;
$background-color: #fdfdfd;
$brand-color:      #474658;
$link-color:       #588FDC;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;
$on-mid-laptop:    980px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "custom",
        "blog"
;
