$light-blue: #3498db;
$light-blue-hover: #2980b9;

.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.page-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $brand-color;
}

.post-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post-item {
  margin-bottom: 3rem;
  border-bottom: 1px solid $grey-color-light;
  padding-bottom: 2rem;

  &:last-child {
    border-bottom: none;
  }
}

.post-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;

  a {
    color: $brand-color;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: darken($brand-color, 15%);
    }
  }
}

.post-meta {
  font-size: 0.9rem;
  color: $grey-color;
  margin-bottom: 1rem;
}

.post-excerpt {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.read-more {
  font-size: 1rem;
  color: $light-blue;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: $light-blue-hover;
    text-decoration: underline;
  }
}

.pagination {
  margin-top: 3rem;
}

.pagination-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-link,
.pagination-previous,
.pagination-next {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  color: $light-blue;
  text-decoration: none;
  border: 1px solid $light-blue;
  border-radius: 3px;
  transition: all 0.3s ease;

  &:hover {
    background-color: $light-blue;
    color: white;
  }
}

.pagination-link.is-current {
  background-color: $light-blue;
  color: white;
  cursor: default;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  
  &:hover {
    background-color: transparent;
    color: $light-blue;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.rss-subscribe {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .home {
    padding: 1rem;
  }

  .page-heading {
    font-size: 2rem;
  }

  .post-title {
    font-size: 1.5rem;
  }

  .pagination-link,
  .pagination-previous,
  .pagination-next {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}