// Variables
$header-height: 50px;
$footer-gradient-start: #40bbe7;
$footer-gradient-end: #0b456d;

// Mixins
@mixin gradient-background($start-color, $end-color) {
  background: $start-color;
  background: linear-gradient(to bottom, $start-color 1%, $end-color 100%);
}

@mixin responsive($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

// Site Header
.site-header {
  min-height: $header-height;
  position: relative;
}

.blog-header {
  text-align: center;
  padding-top: 10px;

  b {
    color: #B0C4DE;
    font-size: 14px;
  }
}

.header-name {
  position: relative;
  float: left;
  top: 15px;
  left: -100px;

  @include responsive($on-mid-laptop) {
    left: -50px;
  }

  @include responsive($on-laptop) {
    left: 0;
  }

  p {
    padding: 0;
    line-height: 13px;
    margin-bottom: 2px;
  }

  .small {
    font-size: 10px;
  }
}

// Navigation
.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline;
    padding-right: 15px;
  }

  a {
    font-size: 12px;
    color: $brand-color;
    border-bottom: none;
    transition: color 0.3s, border-bottom 0.3s;

    &:hover,
    &.active {
      color: #000;
      text-decoration: none;
      border-bottom: solid #505064 3px;
      padding-bottom: 5px;
    }
  }

  @include responsive($on-palm) {
    // Mobile navigation styles
  }
}

// Footer
.site-footer {
  border-top: 1px solid $grey-color-light;
  @include gradient-background($footer-gradient-start, $footer-gradient-end);
  padding: $spacing-unit 0;
  color: #fff;

  a {
    color: #fff;
  }
}

// Footer columns
.footer-col-wrapper {
  @extend %clearfix;
  font-size: 15px;
  margin-left: -$spacing-unit * 0.5;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit * 0.5;
  padding-left: $spacing-unit * 0.5;
}

// Responsive footer columns
@include responsive($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: calc(50% - #{$spacing-unit / 2});
  }

  .footer-col-3 {
    width: calc(100% - #{$spacing-unit / 2});
  }
}

@include responsive($on-palm) {
  .footer-col {
    float: none;
    width: calc(100% - #{$spacing-unit / 2});
  }
}

// Page content
.page-content {
  padding: $spacing-unit 0;
}

// Blog post
.blog-post {
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.post-header {
  margin-bottom: 15px;
}

.post-title {
  font-size: 42px;
  letter-spacing: $base-letter-space;
  line-height: $base-line-height;

  @include responsive($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2, h3, h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 32px;

    @include responsive($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 28px;

    @include responsive($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 24px;

    @include responsive($on-laptop) {
      font-size: 18px;
    }
  }
}

// Pagination
.pagination {
  margin: 0 auto;
  padding-bottom: 10px;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    display: inline-block;
  }

  li {
    display: inline;
    margin: 0 5px;
  }
}

table {
    width: 100%;
    margin-bottom: $spacing-unit;
    border-collapse: collapse;
    border-spacing: 0;
    
    th {
      background-color: $brand-color;
      color: white;
      font-weight: bold;
      text-align: left;
      padding: 10px;
      border: 1px solid darken($brand-color, 10%);
    }
    
    td {
      padding: 8px 10px;
      border: 1px solid $grey-color-light;
    }
    
    tr:nth-child(even) {
      background-color: lighten($grey-color-light, 5%);
    }
    
    tr:hover {
      background-color: lighten($brand-color, 40%);
      transition: background-color 0.3s ease;
    }
    
    @include responsive($on-palm) {
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      
      th, td {
        white-space: nowrap;
      }
    }
  }
  
  .table-container {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: $spacing-unit;
  }