/**
 * Basic Image Modifiers
 */
.left-image {
	float: left;
	margin-right: 20px;
	margin-top: 8px;
	margin-bottom: 10px;
	border: 5px solid black;
	float: left;
	clear: both;

	a:hover{
		color: red;
	}
}

.right-image {
	float: right;
	padding-left: 5px;
}

.twt-btn {
	text-align: center;
}

figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
	margin-inline-end: 0px;
	margin-bottom: 0px;
}

figcaption {
	font-size: 20px;
	font-weight: bold; 
	align-self: flex-end;
	padding-bottom: 0px;
	position: relative;
	display:inline-block;
	left: 10px;
	top: -40px;
	color: #ffffff;
	font-weight: bold;
	background: #474658;
    padding:0em 0.5em 0em 0.5em;/*set top and bottom padding to make up extra height*/
}

.homepage {
	background: #EEF6FF;
	background-image:  linear-gradient(top, #A6CBF9 0%, #EEF6FF 300px); 
	background-image:  -moz-linear-gradient(top, #A6CBF9 0%, #EEF6FF 300px); 
	background-image: -webkit-linear-gradient(top, #A6CBF9 0%, #EEF6FF 300px); 

	background-repeat:repeat-x;
	background-size:100% 300px;

	.home{
		h1{
			&.hero{
			margin-left: -45px;
			@media screen and (max-width: $on-laptop) {
				margin-left: 0px;
				}
			}
			
		}
		p{
			width: 550px;
			@media screen and (max-width: $on-palm) {
				width: 100%;
				}
			}
		}
		.larger{
			font-size: 16px;
		}
		.flex-container {
			display: flex;
			  flex-wrap: wrap;
			  position: relative;
			div {
				display: flex;
				background-color: #f1f1f1;
				width: 220px;
				height: 170px;
				margin-bottom: 10px;
				margin-right: 10px;
				padding: px;
				text-align: left;
				position: relative;
				img{
					width: 100%;
				}
				h2{
					font-size: 14px;
					font-weight: bold; 
					align-self: flex-end;
					padding-bottom: 0px;
					position: absolute;
					left: 10px;
					bottom: 0px;
					color: white;
					text-shadow: 2px 2px 4px #000;
					font-weight: bold;
				}
			  }
		  }
		}